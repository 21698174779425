import * as ws from "lib0/websocket";
import * as logging from "lib0/logging";
import * as buffer from "lib0/buffer";
import * as map from "lib0/map";

import { WebrtcConn, cryptoutils } from "y-webrtc";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  Timestamp,
} from "firebase/firestore";

const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
const db = getFirestore(app);

const log = logging.createModuleLogger("signaling-connection");

/**
 * @param {SignalingConn} conn
 * @param {Room} room
 * @param {any} data
 */
const publishSignalingMessage = (conn, room, data) => {
  if (room.key) {
    cryptoutils.encryptJson(data, room.key).then((data) => {
      conn.send({
        type: "publish",
        topic: room.name,
        data: buffer.toBase64(data),
      });
    });
  } else {
    conn.send({ type: "publish", topic: room.name, data });
  }
};

export class SignalingConnection {
  constructor(url, rooms) {
    this.url = url;

    const handleMessage = (m) => {
      switch (m.type) {
        case "publish":
          {
            const roomName = m.topic;
            const room = rooms.get(roomName);
            if (room == null || typeof roomName !== "string") {
              return;
            }
            const execMessage = (data) => {
              const webrtcConns = room.webrtcConns;
              const peerId = room.peerId;
              if (
                data == null ||
                data.from === peerId ||
                (data.to !== undefined && data.to !== peerId) ||
                room.bcConns.has(data.from)
              ) {
                // ignore messages that are not addressed to this conn, or from clients that are connected via broadcastchannel
                return;
              }
              const emitPeerChange = webrtcConns.has(data.from)
                ? () => {}
                : () =>
                    room.provider.emit("peers", [
                      {
                        removed: [],
                        added: [data.from],
                        webrtcPeers: Array.from(room.webrtcConns.keys()),
                        bcPeers: Array.from(room.bcConns),
                      },
                    ]);
              switch (data.type) {
                case "announce":
                  if (webrtcConns.size < room.provider.maxConns) {
                    map.setIfUndefined(
                      webrtcConns,
                      data.from,
                      () => new WebrtcConn(this, true, data.from, room)
                    );
                    emitPeerChange();
                  }
                  break;
                case "signal":
                  if (data.signal.type === "offer") {
                    const existingConn = webrtcConns.get(data.from);
                    if (existingConn) {
                      const remoteToken = data.token;
                      const localToken = existingConn.glareToken;
                      if (localToken && localToken > remoteToken) {
                        log("offer rejected: ", data.from);
                        return;
                      }
                      // if we don't reject the offer, we will be accepting it and answering it
                      existingConn.glareToken = undefined;
                    }
                  }
                  if (data.signal.type === "answer") {
                    log("offer answered by: ", data.from);
                    const existingConn = webrtcConns.get(data.from);
                    if (existingConn) {
                      existingConn.glareToken = undefined;
                    } else {
                      console.warn('unknown connection answer');
                    }
                  }
                  if (data.to === peerId) {
                    map
                      .setIfUndefined(
                        webrtcConns,
                        data.from,
                        () => new WebrtcConn(this, false, data.from, room)
                      )
                      .peer.signal(data.signal);
                    emitPeerChange();
                  }
                  break;
                default:
                  throw new Error(`unknown type: ${data.type}`);
              }
            };
            if (room.key) {
              if (typeof m.data === "string") {
                cryptoutils
                  .decryptJson(buffer.fromBase64(m.data), room.key)
                  .then(execMessage);
              }
            } else {
              execMessage(m.data);
            }
          }
          break;
        default:
          throw new Error(`unknown message type: ${m.type}`);
      }
    };

    this.unsubscribe = onSnapshot(
      query(
        collection(db, "clover-signaling"),
        where("url", "==", url),
        where("timestamp", ">", Timestamp.now()),
        orderBy("timestamp")
      ),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            handleMessage(change.doc.data().data);
          }
        });
      }
    );

    this.connected = true;

    const topics = Array.from(rooms.keys());
    this.send({ type: "subscribe", topics });
    setTimeout(() => {
      rooms.forEach((room) =>
        publishSignalingMessage(this, room, {
          type: "announce",
          from: room.peerId,
        })
      );
    }, Math.random() * 3000);

    /**
     * @type {Set<WebrtcProvider>}
     */
    this.providers = new Set();
  }

  destroy() {
    this.unsubscribe();
    this.connected = false;
  }

  send(data) {
    if (data.type === "subscribe" || data.type === "unsubscribe") {
      return; // Not supported
    }

    addDoc(collection(db, "clover-signaling"), {
      data,
      url: this.url,
      timestamp: Timestamp.fromMillis(Timestamp.now().toMillis() + 300000),
    });
  }
}

// destroy()
// connected: boolean;
// send
